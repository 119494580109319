import { combineReducers } from 'redux';
import { SIDE_BAR_TOGGLE_SET } from '../constants/sidebar';

const toggle = (state = false, action) => {
    if (action.type === SIDE_BAR_TOGGLE_SET) {
        return !state;
    }

    return state;
};

export default combineReducers({
    toggle,
});
