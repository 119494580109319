import React, { useState } from 'react';
import { Button, Popover } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
// import arrowIcon from '../../../assets/down_arrow.svg';
import { setNetwork } from '../../../actions/network';
// import githubIcon from '../../../assets/social/github.svg';
import upArrow from '../../../assets/up_arrow.svg';
import { withRouter } from 'react-router';

const SelectNetwork = (props) => {
    const [anchorEl, setAnchorEl] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (value) => {
        if (props.active !== 'home') {
            props.history.push('/home');
        }

        props.setNetwork(value);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button
                disableRipple
                aria-haspopup="true"
                aria-owns={open ? 'network-popover' : undefined}
                className={'select_network ' + (props.active === 'home' ? 'active_tab' : '')}
                onClick={handleClick}>
                <span>
                    {props.network === 'main'
                        ? variables[props.lang]['main_networks']
                        : props.network === 'test'
                            ? variables[props.lang]['test_networks']
                            : props.network === 'all'
                                ? variables[props.lang]['all_networks']
                                : variables[props.lang]['select_networks']}
                </span>
                {/* <img alt="icon" src={arrowIcon}/> */}
            </Button>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                className="network_popover"
                id="network-popover"
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleClose}>
                <div className="network_list">
                    <Button
                        className="select_network_button"
                        disabled={true}
                        onClick={() => handleSelect('')}>
                        {variables[props.lang]['select_networks']}
                        <img alt="icon" src={upArrow}/>
                    </Button>
                    <Button
                        className={props.network === 'all' ? 'selected_network' : ''}
                        onClick={() => handleSelect('all')}>
                        {variables[props.lang]['all_networks']}
                    </Button>
                    <Button
                        className={props.network === 'main' ? 'selected_network' : ''}
                        onClick={() => handleSelect('main')}>
                        {variables[props.lang]['main_networks']}
                    </Button>
                    <Button
                        className={props.network === 'test' ? 'selected_network' : ''}
                        onClick={() => handleSelect('test')}>
                        {variables[props.lang]['test_networks']}
                    </Button>
                    {/* <Button */}
                    {/*    className={props.network === 'add' ? 'selected_network add_network' : 'add_network'} */}
                    {/*    onClick={() => handleSelect(props.network)}> */}
                    {/*    <img alt="github" src={githubIcon}/> */}
                    {/*    {variables[props.lang]['add_network']} */}
                    {/* </Button> */}
                </div>
            </Popover>
        </>
    );
};

SelectNetwork.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    network: PropTypes.string.isRequired,
    setNetwork: PropTypes.func.isRequired,
    active: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        network: state.network.networkValue.value,
    };
};

const actionToProps = {
    setNetwork,
};

export default withRouter(connect(stateToProps, actionToProps)(SelectNetwork));
