import { PROPOSALS_FETCH_ERROR, PROPOSALS_FETCH_IN_PROGRESS, PROPOSALS_FETCH_SUCCESS } from '../constants/proposals';
import Axios from 'axios';
import { urlFetchProposals } from '../constants/restURL';

const fetchProposalsInProgress = () => {
    return {
        type: PROPOSALS_FETCH_IN_PROGRESS,
    };
};

const fetchProposalsSuccess = (name, list) => {
    return {
        type: PROPOSALS_FETCH_SUCCESS,
        name,
        list,
    };
};

const fetchProposalsError = (message) => {
    return {
        type: PROPOSALS_FETCH_ERROR,
        message,
    };
};

export const getProposals = (name, URL) => (dispatch) => {
    dispatch(fetchProposalsInProgress());
    const url = urlFetchProposals(URL);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchProposalsSuccess(name, res.data && res.data.proposals));
        })
        .catch((error) => {
            dispatch(fetchProposalsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
