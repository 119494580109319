const variables = {
    en: {
        connect_keplr: 'Connect with Keplr',

        left_header1: 'Participate in community activities via governance proposals & delegate with validators!',
        left_content1: 'DAO stakeholders require to be in sync. OmniFlix inSync is a simple interface for Cosmonauts to interact/engage with protocol DAOs within the Interchain Ecosystem.',
        powered_by: 'powered by',

        add_your_network: '+ Add Your Network',
        select_networks: 'Select Networks',
        all_networks: 'Networks',
        main_networks: 'Main Networks',
        test_networks: 'Test Networks',
        search_network: 'Search Networks...',
        connect_plus: 'Connect +',
        connect: 'Connect',
        stake: 'Stake',
        vote: 'Vote',
        add_network: 'Add Your Network +',
        disconnect: 'Disconnect',
        claim_rewards: 'Claim Rewards',
        select_all: 'Select All',
        approval_pending: 'Approval Pending',
        validators: 'Validators',
        search: 'Search',
        chain: 'Chain',
    },
};

export default variables;
