import {
    ALL_CLAIM_LIST_SET,
    ALL_NETWORK_LIST_SET,
    CLAIM_DIALOG_HIDE,
    CLAIM_DIALOG_SHOW,
    CLAIM_LIST_SET,
    CONNECT_DIALOG_HIDE,
    CONNECT_DIALOG_SHOW,
    NETWORK_LIST_SET,
    NETWORK_SET,
    PRICE_FETCH_ERROR,
    PRICE_FETCH_IN_PROGRESS,
    PRICE_FETCH_SUCCESS,
    SEARCH_VALUE_SET,
} from '../constants/network';
import { combineReducers } from 'redux';
import { DISCONNECT_SET } from '../constants/wallet';

const networkValue = (state = {
    value: 'all',
}, action) => {
    if (action.type === NETWORK_SET) {
        return {
            value: action.value,
        };
    }

    return state;
};

const searchValue = (state = {
    value: '',
}, action) => {
    if (action.type === SEARCH_VALUE_SET) {
        return {
            value: action.value,
        };
    }

    return state;
};

const connectDialog = (state = false, action) => {
    switch (action.type) {
    case CONNECT_DIALOG_SHOW:
        return true;
    case CONNECT_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const networkList = (state = {
    value: new Set(),
    selectAll: false,
}, action) => {
    switch (action.type) {
    case NETWORK_LIST_SET:
        return {
            ...state,
            value: action.value,
        };
    case ALL_NETWORK_LIST_SET:
        return {
            ...state,
            selectAll: action.value,
        };
    case DISCONNECT_SET:
        return {
            value: new Set(),
        };
    default:
        return state;
    }
};

const claimDialog = (state = false, action) => {
    switch (action.type) {
    case CLAIM_DIALOG_SHOW:
        return true;
    case CLAIM_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const claimList = (state = {
    value: new Set(),
    selectAll: false,
}, action) => {
    switch (action.type) {
    case CLAIM_LIST_SET:
        return {
            ...state,
            value: action.value,
        };
    case ALL_CLAIM_LIST_SET:
        return {
            ...state,
            selectAll: action.value,
        };
    case CLAIM_DIALOG_HIDE:
        return {
            value: new Set(),
            selectAll: false,
        };
    case DISCONNECT_SET:
        return {
            value: new Set(),
        };
    default:
        return state;
    }
};

const price = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PRICE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PRICE_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case PRICE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    networkValue,
    searchValue,
    connectDialog,
    networkList,
    claimDialog,
    claimList,
    price,
});
