import React from 'react';
// import SelectNetwork from './SelectNetwork';
// import SearchTextField from './SearchTextField';
// import variables from '../../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import Networks from './Networks';
import { setAllNetworkList, setNetworkList, showClaimDialog, showConnectDialog } from '../../../actions/network';
import Profile from './ConnectDialog/Profile';
import { networks } from '../../../networks';
import TopNav from '../../NavBar/TopNav';

const RightSection = (props) => {
    let chainsList = networks;
    if (props.network === 'main') {
        chainsList = {};
        networks && Object.keys(networks).filter((val) => {
            if (networks[val]['NETWORK_TYPE'] === 'mainnet') {
                chainsList[val] = networks[val];
            }

            return null;
        });
    } else if (props.network === 'test') {
        chainsList = {};
        networks && Object.keys(networks).filter((val) => {
            if (networks[val]['NETWORK_TYPE'] === 'testnet') {
                chainsList[val] = networks[val];
            }

            return null;
        });
    }

    if (props.searchValue && props.searchValue !== '') {
        const object = {};
        chainsList && Object.keys(chainsList).filter((val) => {
            if (chainsList[val]['CHAIN_NAME'].toLowerCase().indexOf(props.searchValue.toLowerCase()) > -1) {
                object[val] = chainsList[val];
            } else if (chainsList[val]['COIN_DENOM'].toLowerCase().indexOf(props.searchValue.toLowerCase()) > -1) {
                object[val] = chainsList[val];
            } else if (chainsList[val]['NETWORK_TYPE'].toLowerCase().indexOf(props.searchValue.toLowerCase()) > -1) {
                object[val] = chainsList[val];
            } else if (chainsList[val]['CHAIN_ID'].toLowerCase().indexOf(props.searchValue.toLowerCase()) > -1) {
                object[val] = chainsList[val];
            }

            return null;
        });

        chainsList = object;
    }

    return (
        <div className="landing_right_section">
            <TopNav/>
            {/* <div className="section1"> */}
            {/*     <SelectNetwork/> */}
            {/*     <Button className="connect_button" onClick={handleValidators}> */}
            {/*         {variables[props.lang].validators} */}
            {/*     </Button> */}
            {/*     <SearchTextField/> */}
            {/*     <Button className="connect_button" onClick={handleConnectDialog}> */}
            {/*         {variables[props.lang]['connect_plus']} */}
            {/*     </Button> */}
            {/* </div> */}
            <div className="scroll_bar">
                <Profile chainsList={chainsList}/>
                <div className="cards">
                    <Networks chainsList={chainsList}/>
                </div>
            </div>
        </div>
    );
};

RightSection.propTypes = {
    address: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    network: PropTypes.string.isRequired,
    networkList: PropTypes.object.isRequired,
    searchValue: PropTypes.string.isRequired,
    setAllNetworkList: PropTypes.func.isRequired,
    setNetworkList: PropTypes.func.isRequired,
    showClaimDialog: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        lang: state.language,
        network: state.network.networkValue.value,
        networkList: state.network.networkList.value,
        searchValue: state.network.searchValue.value,
    };
};

const actionToProps = {
    setAllNetworkList,
    setNetworkList,
    showConnectDialog,
    showClaimDialog,
};

export default connect(stateToProps, actionToProps)(RightSection);
