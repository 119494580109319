import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import logo from '../../assets/logo.svg';
import discordIcon from '../../assets/social/discord.svg';
import telegramIcon from '../../assets/social/telegram.svg';
import githubIcon from '../../assets/social/github.svg';
import twitterIcon from '../../assets/social/twitter.svg';
import websiteIcon from '../../assets/social/website.svg';
import youtubeIcon from '../../assets/social/youtube.svg';
import { Button, IconButton } from '@mui/material';
import ClassNames from 'classnames';
import { socialLinks } from '../../config';
import SelectNetwork from './RightSection/SelectNetwork';
import SearchTextField from './RightSection/SearchTextField';
import { showConnectDialog } from '../../actions/network';
import { withRouter } from 'react-router';
import { setSideBarToggle } from '../../actions/sidebar';

const LeftSection = (props) => {
    const route = props.location.pathname && props.location.pathname.split('/') &&
        props.location.pathname.split('/')[1];
    const handleRedirect = () => {
        props.history.push('/validators');
        props.setSideBarToggle();
    };

    return (
        <div className={ClassNames('landing_left_section', !props.show ? 'hide' : '')}>
            <div className="action_fields">
                <SearchTextField/>
                <SelectNetwork active={route}/>
                <Button
                    className={'connect_button validator_button ' + (route === 'validators' ? 'active_tab' : '')}
                    style={{ marginBottom: '15px' }}
                    onClick={handleRedirect}>
                    {variables[props.lang].validators}
                </Button>
                <Button
                    className="connect_button"
                    style={{ marginBottom: '15px' }}
                    onClick={props.showConnectDialog}>
                    {variables[props.lang].connect_plus}
                </Button>
            </div>
            <div className="section1">
                <img alt="logo" src={logo}/>
                <div className="section_headers">
                    <p className="header">{variables[props.lang]['left_header1']}</p>
                    <p className="content">{variables[props.lang]['left_content1']}</p>
                </div>
                {/* <div className="add_your_network"> */}
                {/*    <Button> */}
                {/*        <img alt="github" src={githubIcon}/> */}
                {/*        {variables[props.lang]['add_network']} */}
                {/*    </Button> */}
                {/* </div> */}
                <div className="social_icons">
                    <IconButton onClick={() => window.open(socialLinks.DISCORD)}>
                        <img alt="icon" src={discordIcon} title="Discord"/>
                    </IconButton>
                    <IconButton onClick={() => window.open(socialLinks.TELEGRAM)}>
                        <img alt="icon" src={telegramIcon} title="Telegram"/>
                    </IconButton>
                    <IconButton onClick={() => window.open(socialLinks.GITHUB)}>
                        <img alt="icon" src={githubIcon} title="Github"/>
                    </IconButton>
                    <IconButton onClick={() => window.open(socialLinks.TWITTER)}>
                        <img alt="icon" src={twitterIcon} title="Twitter"/>
                    </IconButton>
                    <IconButton onClick={() => window.open(socialLinks.WEBSITE)}>
                        <img alt="icon" src={websiteIcon} title="Website"/>
                    </IconButton>
                    <IconButton onClick={() => window.open(socialLinks.YOUTUBE)}>
                        <img alt="icon" src={youtubeIcon} title="Youtube"/>
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

LeftSection.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    setSideBarToggle: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        show: state.sidebar.toggle,
    };
};

const actionToProps = {
    showConnectDialog,
    setSideBarToggle,
};

export default withRouter(connect(stateToProps, actionToProps)(LeftSection));
