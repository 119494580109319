import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import variables from '../../../utils/variables';
import { InputAdornment } from '@mui/material';
import { setSearchValue } from '../../../actions/network';
import { ReactComponent as SearchIcon } from '../../../assets/search.svg';

const SearchTextField = (props) => {
    const handleChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            className="search_field"
            inputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon/>
                    </InputAdornment>
                ),
            }}
            name="searchNetwork"
            placeholder={variables[props.lang]['search_network']}
            value={props.value}
            onChange={handleChange}/>
    );
};

SearchTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.network.searchValue.value,
    };
};

const actionsToProps = {
    onChange: setSearchValue,
};

export default connect(stateToProps, actionsToProps)(SearchTextField);
