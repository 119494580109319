import React, { Component } from 'react';
import './index.css';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConnectDialog from './RightSection/ConnectDialog';
import { fetchPrice, setNetworkList } from '../../actions/network';
import { chainConfig } from '../../utils/chainConfig';
import { networks } from '../../networks';
import { fetchRewards, initializeChain } from '../../actions/account/wallet';
import { fetchBalance } from '../../actions/account/BCDetails';
import { getProposals } from '../../actions/proposals';
import ClaimDialog from './RightSection/ClaimRewards';
import NavBar from '../NavBar';

class Home extends Component {
    constructor (props) {
        super(props);

        this.initKeplr = this.initKeplr.bind(this);
    }

    componentDidMount () {
        if (this.props.networkList && this.props.networkList.size === 0) {
            let obj = sessionStorage.getItem('of_insync_connections') || '{}';
            obj = obj && JSON.parse(obj);
            if (obj && Object.keys(obj).length) {
                const array = [];
                const priceList = [];
                setTimeout(() => {
                    Object.keys(obj).map((val) => {
                        array.push(val);
                        if (networks[val].COINGECKO_ID !== '-') {
                            priceList.push(networks[val].COINGECKO_ID);
                        }

                        return null;
                    });

                    this.initKeplr(array, true);
                    if (priceList.length) {
                        this.props.fetchPrice(priceList);
                    }
                }, 600);
            }
        }
    }

    initKeplr (data, fetchUser) {
        if (data && data.length && data[0]) {
            const name = data[0];
            this.props.initializeChain(name, networks[name], chainConfig(networks[name]), (address) => {
                if (address) {
                    const list = new Set(this.props.networkList);

                    if (list.has(name)) {
                        list.delete(name);
                    } else {
                        list.add(name);
                    }

                    this.props.setNetworkList(list);
                    this.props.fetchBalance(address.length && address[0] && address[0].address, name, networks[name].REST_URL);
                    this.props.getProposals(name, networks[name].REST_URL);
                    this.props.fetchRewards(address.length && address[0] && address[0].address, name, networks[name].REST_URL);

                    data.splice(0, 1);
                    this.initKeplr(data, false);
                }
            });
        }
    }

    render () {
        return (
            <div className="home">
                <NavBar/>
                <LeftSection/>
                <RightSection/>
                <ConnectDialog/>
                <ClaimDialog/>
            </div>
        );
    }
}

Home.propTypes = {
    fetchBalance: PropTypes.func.isRequired,
    fetchPrice: PropTypes.func.isRequired,
    fetchRewards: PropTypes.func.isRequired,
    getProposals: PropTypes.func.isRequired,
    initializeChain: PropTypes.func.isRequired,
    networkList: PropTypes.object.isRequired,
    setNetworkList: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    userName: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        show: state.sidebar.toggle,
        networkList: state.network.networkList.value,
        userName: state.account.wallet.userName,
    };
};

const actionToProps = {
    fetchBalance,
    fetchPrice,
    fetchRewards,
    getProposals,
    initializeChain,
    setNetworkList,
};

export default connect(stateToProps, actionToProps)(Home);
