export const config = {
    RPC_URL: 'https://rpc.flixnet-4.omniflix.network',
    REST_URL: 'https://rest.flixnet-4.omniflix.network',
    STAKING_URL: 'https://flix.omniflix.co/stake',
    CHAIN_ID: 'flixnet-4',
    CHAIN_NAME: 'OmniFlix FlixNet-4',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
    COIN_TYPE: 118,
    COINGECKO_ID: '-',
    GAS_PRICE_STEP_LOW: 0.0025,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go'],
};

export const socialLinks = {
    TWITTER: 'https://twitter.com/OmniFlixNetwork',
    TELEGRAM: 'https://t.me/OmniFlixChat',
    DISCORD: 'https://discord.com/invite/6gdQ4yZSTC',
    GITHUB: 'https://github.com/OmniFlix',
    WEBSITE: 'https://omniflix.network/',
    YOUTUBE: 'https://www.youtube.com/OmniFlixNetwork',
};

export const appsLinks = {
    STUDIO: 'https://app.omniflix.studio',
    MARKET: 'https://omniflix.market',
    TV: 'https://omniflix.tv',
};
