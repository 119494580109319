import React, { useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import { hideClaimDialog, setAllClaimList, setClaimList } from '../../../../actions/network';
import { Button, Checkbox, FormControlLabel, ListItem, RadioGroup } from '@mui/material';
import variables from '../../../../utils/variables';
import { networks } from '../../../../networks';
import './index.css';
import '../ConnectDialog/index.css';
import { chainConfig } from '../../../../utils/chainConfig';
import { fetchBalance } from '../../../../actions/account/BCDetails';
import { fetchRewards, initializeChain } from '../../../../actions/account/wallet';
import { getProposals } from '../../../../actions/proposals';
import { gas } from '../../../../utils/defaultGasValues';
import { config } from '../../../../config';
import { signTxAndBroadcast } from '../../../../helper';
import { showMessage } from '../../../../actions/snackbar';
import CircularProgress from '../../../../components/CircularProgress';

const ClaimDialog = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const {
        onClose,
        open,
    } = props;

    const handleClaimAll = () => {
        const array = [];
        props.rewards && Object.keys(props.rewards).map((val) => {
            setInProgress(true);
            if (props.value.has(val) && props.rewards[val] &&
                props.rewards[val].total && props.rewards[val].total.length) {
                array.push(val);
            }

            return null;
        });

        handleFetch(array);
    };

    const handleFetch = (data, object) => {
        if (data && data.length && data[0]) {
            const name = data[0];
            let gasValue = gas.claim_reward;
            const rewards = props.rewards && props.rewards[name];
            const address = props.address && props.address[name];

            if (rewards && rewards.rewards && rewards.rewards.length > 1) {
                gasValue = (rewards.rewards.length - 1) / 2 * gas.claim_reward + gas.claim_reward;
            }

            const updatedTx = {
                msgs: [],
                fee: {
                    amount: [{
                        amount: String(gasValue * config.GAS_PRICE_STEP_AVERAGE),
                        denom: config.COIN_MINIMAL_DENOM,
                    }],
                    gas: String(gasValue),
                },
                memo: '',
            };

            if (rewards && rewards.rewards &&
                rewards.rewards.length) {
                rewards.rewards.map((item) => {
                    updatedTx.msgs.push({
                        typeUrl: '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
                        value: {
                            delegatorAddress: address,
                            validatorAddress: item.validator_address,
                        },
                    });

                    return null;
                });
            } else {
                setInProgress(false);
                props.showMessage('validator address not found');
                return;
            }

            signTxAndBroadcast(updatedTx, address, name, networks[name], chainConfig(networks[name]), (error, result) => {
                setInProgress(false);
                if (error) {
                    if (error.indexOf('not yet found on the chain') > -1) {
                        return;
                    }

                    props.showMessage(error);
                    return;
                }
                if (result) {
                    props.showMessage('Transaction Hash: ' + result.transactionHash);
                    props.fetchRewards(address, name, networks[name].REST_URL);
                    props.fetchBalance(address, name, networks[name].REST_URL);
                }

                data.splice(0, 1);
                const list = new Set(props.value);
                list.delete(name);
                props.setClaimList(list);
                handleFetch(data, object);
            });
        } else {
            setInProgress(false);
            props.setClaimList(new Set());
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        const list = new Set(props.value);

        if (list.has(value)) {
            list.delete(value);
        } else {
            list.add(value);
        }

        if ((list.size === 0) && props.selectAll) {
            props.setAllClaimList(false);
        } else if (props.rewards && (Object.keys(props.rewards).length === list.size)) {
            props.setAllClaimList(true);
        }

        props.setClaimList(list);
    };

    const handleSelectAll = () => {
        const list = new Set(props.value);
        if (props.selectAll) {
            list.clear();
        } else {
            props.rewards && Object.keys(props.rewards).map((value) => {
                let balance = networks[value] && networks[value].COIN_MINIMAL_DENOM &&
                    props.rewards[value] && props.rewards[value].total &&
                    props.rewards[value].total.find((val) => val.denom === networks[value].COIN_MINIMAL_DENOM);
                balance = balance && balance.amount && (balance.amount / (10 ** networks[value].COIN_DECIMALS));
                balance = balance > 0 ? parseFloat(balance).toFixed(4) : 0;
                if (!list.has(value) && (balance && balance > 0)) {
                    list.add(value);
                }

                return null;
            });
        }

        props.setAllClaimList(!props.selectAll);
        props.setClaimList(list);
    };

    let chainsList = networks;
    if (props.network === 'main') {
        chainsList = {};
        networks && Object.keys(networks).filter((val) => {
            if (networks[val]['NETWORK_TYPE'] === 'mainnet') {
                chainsList[val] = networks[val];
            }

            return null;
        });
    } else if (props.network === 'test') {
        chainsList = {};
        networks && Object.keys(networks).filter((val) => {
            if (networks[val]['NETWORK_TYPE'] === 'testnet') {
                chainsList[val] = networks[val];
            }

            return null;
        });
    }

    return (
        <Dialog className="network_dialog claim_dialog" open={open} onClose={handleClose}>
            {inProgress && <CircularProgress className="full_screen"/>}
            <div className="section_header">
                <RadioGroup
                    row
                    aria-label="position"
                    name="position">
                    <FormControlLabel
                        control={<Checkbox checked={props.selectAll} color="primary" onChange={handleSelectAll}/>}
                        label={variables[props.lang]['select_all']}
                        labelPlacement="end"
                        value="end"/>
                </RadioGroup>
                <Button
                    disabled={inProgress}
                    variant="contained"
                    onClick={handleClaimAll}>
                    {inProgress
                        ? variables[props.lang]['approval_pending'] + '...'
                        : <>
                            {variables[props.lang]['claim_rewards']}
                            <p>({props.value.size})</p>
                        </>}
                </Button>
            </div>
            <List className="scroll_bar" sx={{ pt: 0 }}>
                {chainsList && Object.keys(chainsList).map((val) => {
                    let balance = networks[val] && networks[val].COIN_MINIMAL_DENOM &&
                        props.rewards[val] && props.rewards[val].total &&
                        props.rewards[val].total.find((value) => value.denom === networks[val].COIN_MINIMAL_DENOM);
                    balance = balance && balance.amount && (balance.amount / (10 ** networks[val].COIN_DECIMALS));
                    balance = balance > 0 ? parseFloat(balance).toFixed(4) : 0;

                    if (balance > 0) {
                        return (
                            <ListItem
                                key={val}
                                className={props.value.has(val) ? 'checked_network' : ''}
                                onClick={() => handleListItemClick(val)}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={props.value.has(val)}
                                        sx={{
                                            color: '#ffffff',
                                            '&.Mui-checked': {
                                                color: '#6228E6',
                                            },
                                        }}
                                        onChange={() => handleListItemClick(val)}
                                    />}
                                    label={<div className="chain_section" onClick={(event) => event.stopPropagation()}>
                                        <span className="chain_logo">
                                            {networks[val].logo && <img alt={val} src={networks[val].logo}/>}
                                        </span>
                                        <div className="chain_details">
                                            <span className="chain_name">{networks[val]['CHAIN_NAME']}</span>
                                            <div className="rewards">
                                                <p>{balance}</p>
                                                {networks[val]['COIN_DENOM']}
                                            </div>
                                        </div>
                                    </div>}/>
                            </ListItem>
                        );
                    }

                    return null;
                })}
            </List>
        </Dialog>
    );
};

ClaimDialog.propTypes = {
    address: PropTypes.object.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchRewards: PropTypes.func.isRequired,
    getProposals: PropTypes.func.isRequired,
    initializeChain: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    network: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    rewards: PropTypes.object.isRequired,
    selectAll: PropTypes.bool.isRequired,
    setAllClaimList: PropTypes.func.isRequired,
    setClaimList: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        lang: state.language,
        open: state.network.claimDialog,
        value: state.network.claimList.value,
        selectAll: state.network.claimList.selectAll,
        rewards: state.account.wallet.rewards.result,
        network: state.network.networkValue.value,
    };
};

const actionToProps = {
    fetchBalance,
    getProposals,
    initializeChain,
    onClose: hideClaimDialog,
    setAllClaimList,
    setClaimList,
    fetchRewards,
    showMessage,
};

export default connect(stateToProps, actionToProps)(ClaimDialog);
