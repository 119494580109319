import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import account from './account';
import network from './network';
import sidebar from './sidebar';
import proposals from './proposals';
import validators from './validators';

export default combineReducers({
    account,
    language,
    snackbar,
    network,
    sidebar,
    proposals,
    validators,
});
