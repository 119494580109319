export const NETWORK_SET = 'NETWORK_SET';
export const SEARCH_VALUE_SET = 'SEARCH_VALUE_SET';

export const CONNECT_DIALOG_SHOW = 'CONNECT_DIALOG_SHOW';
export const CONNECT_DIALOG_HIDE = 'CONNECT_DIALOG_HIDE';

export const NETWORK_LIST_SET = 'NETWORK_LIST_SET';
export const ALL_NETWORK_LIST_SET = 'ALL_NETWORK_LIST_SET';

export const CLAIM_DIALOG_SHOW = 'CLAIM_DIALOG_SHOW';
export const CLAIM_DIALOG_HIDE = 'CLAIM_DIALOG_HIDE';

export const CLAIM_LIST_SET = 'CLAIM_LIST_SET';
export const ALL_CLAIM_LIST_SET = 'ALL_CLAIM_LIST_SET';

export const PRICE_FETCH_IN_PROGRESS = 'PRICE_FETCH_IN_PROGRESS';
export const PRICE_FETCH_SUCCESS = 'PRICE_FETCH_SUCCESS';
export const PRICE_FETCH_ERROR = 'PRICE_FETCH_ERROR';
