export const COIN_GECKO_URL = 'https://api.coingecko.com';

export const urlFetchBalance = (address, URL) => `${URL}/cosmos/bank/v1beta1/balances/${address}`;
export const urlFetchProposals = (URL) => `${URL}/cosmos/gov/v1beta1/proposals`;

export const urlFetchRewards = (address, URL) => `${URL}/cosmos/distribution/v1beta1/delegators/${address}/rewards`;

export const urlFetchPrice = (coins) => {
    return `${COIN_GECKO_URL}/api/v3/simple/price?ids=${coins.join(',')}&vs_currencies=usd`;
};
