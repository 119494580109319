import comdexLogo from './assets/chains/comdex.svg';
import junoLogo from './assets/chains/juno.svg';
import likeLogo from './assets/chains/like.svg';
import chihuahuaLogo from './assets/chains/chihuahua.svg';
import cheqdLogo from './assets/chains/cheqd.svg';
import fetchLogo from './assets/chains/fetch.svg';
import flixLogo from './assets/chains/flix.svg';
import spayLogo from './assets/chains/spay.svg';
import bitcannaLogo from './assets/chains/bitcanna.svg';
import cosmosLogo from './assets/chains/atom.svg';
import akashLogo from './assets/chains/akash.png';
import osmosisLogo from './assets/chains/osmosis.svg';
import regenLogo from './assets/chains/regen.svg';
import persistenceLogo from './assets/chains/persistence.svg';
import sentinelLogo from './assets/chains/dvpn.svg';
import assetMantleLogo from './assets/chains/assetmantle.svg';
import secretLogo from './assets/chains/secret.svg';

export const networks = {
    flix: {
        logo: flixLogo,
        RPC_URL: 'https://rpc.omniflix.network',
        REST_URL: 'https://rest.omniflix.network',
        APP_URL: 'https://flix.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'omniflixhub-1',
        CHAIN_NAME: 'OmniFlix Hub',
        COIN_DENOM: 'FLIX',
        COIN_MINIMAL_DENOM: 'uflix',
        COIN_DECIMALS: 6,
        PREFIX: 'omniflix',
        COIN_TYPE: 118,
        COINGECKO_ID: 'omniflix-network',
        GAS_PRICE_STEP_LOW: 0.001,
        GAS_PRICE_STEP_AVERAGE: 0.0025,
        GAS_PRICE_STEP_HIGH: 0.025,
        FEATURES: ['stargate', 'ibc-transfer', 'no-legacy-stdTx', 'ibc-go'],
    },
    juno: {
        logo: junoLogo,
        RPC_URL: 'https://rpc-juno.omniflix.io',
        REST_URL: 'https://api-juno.omniflix.io',
        APP_URL: 'https://juno.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'juno-1',
        CHAIN_NAME: 'Juno',
        COIN_DENOM: 'JUNO',
        COIN_MINIMAL_DENOM: 'ujuno',
        COIN_DECIMALS: 6,
        PREFIX: 'juno',
        COIN_TYPE: 118,
        COINGECKO_ID: 'juno-network',
        DEFAULT_GAS: 200000,
        GAS_PRICE_STEP_LOW: 0.01,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer', 'cosmwasm', 'ibc-go'],
    },
    akashnet: {
        logo: akashLogo,
        RPC_URL: 'https://rpc-akash-ia.cosmosia.notional.ventures:443',
        REST_URL: 'https://api-akash-ia.cosmosia.notional.ventures',
        APP_URL: 'https://akash.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'akashnet-2',
        CHAIN_NAME: 'Akash Network',
        COIN_DENOM: 'AKT',
        COIN_MINIMAL_DENOM: 'uakt',
        COIN_DECIMALS: 6,
        PREFIX: 'akash',
        COIN_TYPE: 118,
        COINGECKO_ID: 'akash-network',
        GAS_PRICE_STEP_LOW: 0.01,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer'],
    },
    assetmantle: {
        logo: assetMantleLogo,
        RPC_URL: 'https://rpc-assetmantle-ia.cosmosia.notional.ventures',
        REST_URL: 'https://api-assetmantle-ia.cosmosia.notional.ventures',
        APP_URL: 'https://assetmantle.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'mantle-1',
        CHAIN_NAME: 'AssetMantle',
        COIN_DENOM: 'MNTL',
        COIN_MINIMAL_DENOM: 'umntl',
        COIN_DECIMALS: 6,
        PREFIX: 'mantle',
        COIN_TYPE: 118,
        COINGECKO_ID: 'assetmantle',
        GAS_PRICE_STEP_LOW: 0.01,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer'],
    },
    bitcanna: {
        logo: bitcannaLogo,
        RPC_URL: 'https://rpc.bitcanna.io',
        REST_URL: 'https://lcd.bitcanna.io',
        APP_URL: 'https://bitcanna.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'bitcanna-1',
        CHAIN_NAME: 'BitCanna-1',
        COIN_DENOM: 'BCNA',
        COIN_MINIMAL_DENOM: 'ubcna',
        COIN_DECIMALS: 6,
        PREFIX: 'bcna',
        COIN_TYPE: 118,
        COINGECKO_ID: 'bitcanna',
        GAS_PRICE_STEP_LOW: 0.001,
        GAS_PRICE_STEP_AVERAGE: 0.01,
        GAS_PRICE_STEP_HIGH: 0.05,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    cheqd: {
        logo: cheqdLogo,
        RPC_URL: 'https://rpc.cheqd.net',
        REST_URL: 'https://api.cheqd.net',
        APP_URL: 'https://cheqd.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'cheqd-mainnet-1',
        CHAIN_NAME: 'Cheqd Mainnet',
        COIN_DENOM: 'CHEQ',
        COIN_MINIMAL_DENOM: 'ncheq',
        COIN_DECIMALS: 9,
        PREFIX: 'cheqd',
        COIN_TYPE: 118,
        COINGECKO_ID: 'cheqd-network',
        GAS_PRICE_STEP_LOW: 25,
        GAS_PRICE_STEP_AVERAGE: 30,
        GAS_PRICE_STEP_HIGH: 50,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    chihuahua: {
        logo: chihuahuaLogo,
        RPC_URL: 'https://rpc.chihuahua.wtf',
        REST_URL: 'https://api.chihuahua.wtf',
        APP_URL: 'https://chihuahua.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'chihuahua-1',
        CHAIN_NAME: 'Chihuahua',
        COIN_DENOM: 'HUAHUA',
        COIN_MINIMAL_DENOM: 'uhuahua',
        COIN_DECIMALS: 6,
        PREFIX: 'chihuahua',
        COIN_TYPE: 118,
        COINGECKO_ID: 'chihuahua-token',
        GAS_PRICE_STEP_LOW: 0.025,
        GAS_PRICE_STEP_AVERAGE: 0.03,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    comdex: {
        logo: comdexLogo,
        RPC_URL: 'https://rpc-comdex.carbonZERO.zone:443',
        REST_URL: 'https://rest-comdex.carbonZERO.zone:443',
        APP_URL: 'https://comdex.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'comdex-1',
        CHAIN_NAME: 'Comdex',
        COIN_DENOM: 'CMDX',
        COIN_MINIMAL_DENOM: 'ucmdx',
        COIN_DECIMALS: 6,
        PREFIX: 'comdex',
        COIN_TYPE: 118,
        COINGECKO_ID: 'comdex',
        GAS_PRICE_STEP_LOW: 0.025,
        GAS_PRICE_STEP_AVERAGE: 0.03,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    cosmos: {
        logo: cosmosLogo,
        RPC_URL: 'https://rpc-cosmoshub-ia.cosmosia.notional.ventures',
        REST_URL: 'https://api-cosmoshub-ia.cosmosia.notional.ventures',
        APP_URL: 'https://cosmos.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'cosmoshub-4',
        CHAIN_NAME: 'Cosmos Network',
        COIN_DENOM: 'ATOM',
        COIN_MINIMAL_DENOM: 'uatom',
        COIN_DECIMALS: 6,
        PREFIX: 'cosmos',
        COIN_TYPE: 118,
        COINGECKO_ID: 'cosmos',
        GAS_PRICE_STEP_LOW: 0.01,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    fetch: {
        logo: fetchLogo,
        RPC_URL: 'https://rpc-fetchhub-ia.cosmosia.notional.ventures',
        REST_URL: 'https://api-fetchhub-ia.cosmosia.notional.ventures',
        APP_URL: 'https://fetch.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'fetchhub-4',
        CHAIN_NAME: 'Fetch',
        COIN_DENOM: 'FET',
        COIN_MINIMAL_DENOM: 'afet',
        COIN_DECIMALS: 18,
        PREFIX: 'fetch',
        COIN_TYPE: 118,
        COINGECKO_ID: 'fetch-ai',
        GAS_PRICE_STEP_LOW: 0.01,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    like: {
        logo: likeLogo,
        RPC_URL: 'https://mainnet-node.like.co/rpc/',
        REST_URL: 'https://mainnet-node.like.co',
        APP_URL: 'https://likecoin.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'likecoin-mainnet-2',
        CHAIN_NAME: 'LikeCoin',
        COIN_DENOM: 'LIKE',
        COIN_MINIMAL_DENOM: 'nanolike',
        COIN_DECIMALS: 9,
        PREFIX: 'like',
        COIN_TYPE: 118,
        COINGECKO_ID: 'likecoin',
        GAS_PRICE_STEP_LOW: 0.01,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    osmosis: {
        logo: osmosisLogo,
        RPC_URL: 'https://rpc-osmosis.omniflix.io',
        REST_URL: 'https://api-osmosis.omniflix.io',
        APP_URL: 'https://osmosis.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'osmosis-1',
        CHAIN_NAME: 'Osmosis',
        COIN_DENOM: 'OSMO',
        COIN_MINIMAL_DENOM: 'uosmo',
        COIN_DECIMALS: 6,
        PREFIX: 'osmo',
        COIN_TYPE: 118,
        COINGECKO_ID: 'osmosis',
        GAS_PRICE_STEP_LOW: 0,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    persistence: {
        logo: persistenceLogo,
        RPC_URL: 'https://rpc-persistent-ia.cosmosia.notional.ventures',
        REST_URL: 'https://api-persistent-ia.cosmosia.notional.ventures',
        APP_URL: 'https://persistence.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'core-1',
        CHAIN_NAME: 'Persistence',
        COIN_DENOM: 'XPRT',
        COIN_MINIMAL_DENOM: 'uxprt',
        COIN_DECIMALS: 6,
        PREFIX: 'persistence',
        COIN_TYPE: 118,
        COINGECKO_ID: 'persistence',
        GAS_PRICE_STEP_LOW: 0,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-go', 'ibc-transfer'],
    },
    regen: {
        logo: regenLogo,
        RPC_URL: 'https://rpc-regen-ia.cosmosia.notional.ventures',
        REST_URL: 'https://api-regen-ia.cosmosia.notional.ventures',
        APP_URL: 'https://wallet.keplr.app/#/regen',
        GOV_URL: 'https://wallet.keplr.app/#/regen/governance',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'regen-1',
        CHAIN_NAME: 'Regen',
        COIN_DENOM: 'REGEN',
        COIN_MINIMAL_DENOM: 'uregen',
        COIN_DECIMALS: 6,
        PREFIX: 'regen',
        COIN_TYPE: 118,
        COINGECKO_ID: 'regen',
        GAS_PRICE_STEP_LOW: 0.01,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-go', 'ibc-transfer'],
    },
    secret: {
        logo: secretLogo,
        RPC_URL: 'https://rpc.secret.express',
        REST_URL: 'https://lcd.secret.express',
        APP_URL: 'https://secret.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'secret-4',
        CHAIN_NAME: 'Secret Network',
        COIN_DENOM: 'SCRT',
        COIN_MINIMAL_DENOM: 'uscrt',
        COIN_DECIMALS: 6,
        PREFIX: 'secret',
        COIN_TYPE: 529,
        COINGECKO_ID: 'secret',
        GAS_PRICE_STEP_LOW: 0.0125,
        GAS_PRICE_STEP_AVERAGE: 0.1,
        GAS_PRICE_STEP_HIGH: 0.25,
        FEATURES: ['secretwasm', 'ibc-transfer', 'ibc-go'],
    },
    sentinel: {
        logo: sentinelLogo,
        RPC_URL: 'https://rpc-sentinel-ia.cosmosia.notional.ventures',
        REST_URL: 'https://api-sentinel-ia.cosmosia.notional.ventures',
        APP_URL: 'https://sentinel.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'mainnet',
        CHAIN_ID: 'sentinelhub-2',
        CHAIN_NAME: 'Sentinel',
        COIN_DENOM: 'DVPN',
        COIN_MINIMAL_DENOM: 'udvpn',
        COIN_DECIMALS: 6,
        PREFIX: 'sent',
        COIN_TYPE: 118,
        COINGECKO_ID: 'sentinel',
        GAS_PRICE_STEP_LOW: 0.1,
        GAS_PRICE_STEP_AVERAGE: 0.25,
        GAS_PRICE_STEP_HIGH: 0.4,
        FEATURES: ['ibc-transfer'],
    },
    flixnet: {
        logo: flixLogo,
        RPC_URL: 'https://rpc.flixnet-4.omniflix.network',
        REST_URL: 'https://rest.flixnet-4.omniflix.network',
        APP_URL: 'https://flix-f4.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'testnet',
        CHAIN_ID: 'flixnet-4',
        CHAIN_NAME: 'OmniFlix FlixNet-4',
        COIN_DENOM: 'FLIX',
        COIN_MINIMAL_DENOM: 'uflix',
        COIN_DECIMALS: 6,
        PREFIX: 'omniflix',
        COIN_TYPE: 118,
        COINGECKO_ID: '-',
        GAS_PRICE_STEP_LOW: 0.001,
        GAS_PRICE_STEP_AVERAGE: 0.0025,
        GAS_PRICE_STEP_HIGH: 0.025,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    spay: {
        logo: spayLogo,
        RPC_URL: 'https://rpc.streampay.omniflix.network',
        REST_URL: 'https://rest.streampay.omniflix.network',
        NETWORK_TYPE: 'testnet',
        CHAIN_ID: 'streampay-1',
        CHAIN_NAME: 'Stream Pay Testnet',
        COIN_DENOM: 'SPAY',
        COIN_MINIMAL_DENOM: 'uspay',
        COIN_DECIMALS: 6,
        PREFIX: 'streampay',
        COIN_TYPE: 118,
        COINGECKO_ID: '-',
        GAS_PRICE_STEP_LOW: 0.0025,
        GAS_PRICE_STEP_AVERAGE: 0.025,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer', 'ibc-go'],
    },
    junox: {
        logo: junoLogo,
        RPC_URL: 'https://rpc.uni.kingnodes.com',
        REST_URL: 'https://api.uni.kingnodes.com',
        APP_URL: 'https://uni.juno.omniflix.co',
        OMNI_FLIX_LINK: true,
        NETWORK_TYPE: 'testnet',
        CHAIN_ID: 'uni-5',
        CHAIN_NAME: 'Juno Uni Testnet',
        COIN_DENOM: 'JUNOX',
        COIN_MINIMAL_DENOM: 'ujunox',
        COIN_DECIMALS: 6,
        PREFIX: 'juno',
        COIN_TYPE: 118,
        COINGECKO_ID: '-',
        GAS_PRICE_STEP_LOW: 0.025,
        GAS_PRICE_STEP_AVERAGE: 0.03,
        GAS_PRICE_STEP_HIGH: 0.04,
        FEATURES: ['ibc-transfer', 'cosmwasm', 'ibc-go'],
    },
};
