import React from 'react';
import { IconButton } from '@mui/material';
import menuIcon from '../../assets/menu.svg';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import * as PropTypes from 'prop-types';
import { setSideBarToggle } from '../../actions/sidebar';
import { connect } from 'react-redux';
import './index.css';

const NavBar = (props) => {
    const handleToggle = () => {
        props.setSideBarToggle();
    };

    return (
        <>
            <div className={props.show ? 'menu_section side_pan_show' : 'menu_section'}>
                <IconButton onClick={handleToggle}>
                    <img alt="menu" src={menuIcon}/>
                </IconButton>
                <Logo/>
            </div>
            {props.show ? <div className="back_pan" onClick={handleToggle}/> : null}
        </>
    );
};

NavBar.propTypes = {
    setSideBarToggle: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        show: state.sidebar.toggle,
    };
};

const actionToProps = {
    setSideBarToggle,
};

export default connect(stateToProps, actionToProps)(NavBar);
