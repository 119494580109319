import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import { withRouter } from 'react-router';
import LoadingPage from './containers/LoadingPage';
// import Validators from './containers/Validators';
// import SingleValidator from './containers/SingleValidator';

const routes = [{
    path: '/',
    component: LoadingPage,
}, {
    path: '/home',
    component: Home,
}];
// , {
//     path: '/validators',
//     component: Validators,
// }, {
//     path: '/validators/:validatorName',
//     component: SingleValidator,
// }];

const Router = () => {
    return (
        <div className="main_content">
            <div className="content_div scroll_bar">
                <Switch>
                    {routes.map((route) =>
                        <Route
                            key={route.path}
                            exact
                            component={withRouter(route.component)}
                            path={route.path}/>,
                    )}
                    <Route
                        exact
                        component={Home}
                        path="*"/>
                </Switch>
            </div>
        </div>
    );
};

export default Router;
