export const CONNECT_KEPLR_ACCOUNT_IN_PROGRESS = 'CONNECT_KEPLR_ACCOUNT_IN_PROGRESS';
export const CONNECT_KEPLR_ACCOUNT_SUCCESS = 'CONNECT_KEPLR_ACCOUNT_SUCCESS';
export const CONNECT_KEPLR_ACCOUNT_ERROR = 'CONNECT_KEPLR_ACCOUNT_ERROR';

export const KEPLR_ACCOUNT_KEYS_SET = 'KEPLR_ACCOUNT_KEYS_SET';

export const BALANCE_FETCH_IN_PROGRESS = 'BALANCE_FETCH_IN_PROGRESS';
export const BALANCE_FETCH_SUCCESS = 'BALANCE_FETCH_SUCCESS';
export const BALANCE_FETCH_ERROR = 'BALANCE_FETCH_ERROR';

export const DISCONNECT_SET = 'DISCONNECT_SET';
export const USER_NAME_SET = 'USER_NAME_SET';

export const REWARDS_FETCH_IN_PROGRESS = 'REWARDS_FETCH_IN_PROGRESS';
export const REWARDS_FETCH_SUCCESS = 'REWARDS_FETCH_SUCCESS';
export const REWARDS_FETCH_ERROR = 'REWARDS_FETCH_ERROR';
