import React, { Component } from 'react';
import { Button } from '@mui/material';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import '../Home/RightSection/index.css';
import SelectNetwork from '../Home/RightSection/SelectNetwork';
import SearchTextField from '../Home/RightSection/SearchTextField';
import variables from '../../utils/variables';
import { networks } from '../../networks';
import { setAllNetworkList, setNetworkList, showConnectDialog } from '../../actions/network';
import AppsPopover from './AppsPopover';

class NavBar extends Component {
    constructor (props) {
        super(props);

        this.handleConnectDialog = this.handleConnectDialog.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.state = {
            value: 'home',
        };
    }

    componentDidMount () {
        const route = this.props.location.pathname && this.props.location.pathname.split('/') &&
            this.props.location.pathname.split('/')[1];

        if (route === '') {
            this.props.history.push('/home');
        }

        if (this.state.value !== route) {
            this.setState({
                value: route,
            });
        }
    }

    handleConnectDialog () {
        this.props.showConnectDialog();

        let obj = sessionStorage.getItem('of_insync_connections') || '{}';
        obj = obj && JSON.parse(obj);
        const list = new Set(this.props.networkList);

        if (obj && Object.keys(obj).length) {
            Object.keys(obj).map((val) => {
                if (!list.has(val)) {
                    list.add(val);
                }

                return null;
            });
        }

        if (networks && (Object.keys(networks).length === list.size)) {
            this.props.setAllNetworkList(true);
        } else {
            this.props.setAllNetworkList(false);
        }

        this.props.setNetworkList(list);
    }

    handleRedirect () {
        this.props.history.push('/validators');
    }

    render () {
        return (
            <div className="section1">
                <div className="search_div">
                    <SearchTextField/>
                    {/* <Button */}
                    {/*     disableRipple */}
                    {/*     className={'validator_button ' + (this.state.value === 'validators' ? 'active_tab' : '')} */}
                    {/*     onClick={this.handleRedirect}> */}
                    {/*     {variables[this.props.lang].validators} */}
                    {/* </Button> */}
                    <SelectNetwork active={this.state.value}/>
                </div>
                <AppsPopover/>
                <Button
                    className="connect_button"
                    onClick={this.handleConnectDialog}>
                    {variables[this.props.lang]['connect_plus']}
                </Button>
            </div>
        );
    }
}

NavBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    networkList: PropTypes.object.isRequired,
    setAllNetworkList: PropTypes.func.isRequired,
    setNetworkList: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        networkList: state.network.networkList.value,
    };
};

const actionToProps = {
    setAllNetworkList,
    setNetworkList,
    showConnectDialog,
};

export default withRouter(connect(stateToProps, actionToProps)(NavBar));
