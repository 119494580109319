import React from 'react';
import { Button } from '@mui/material';
import * as PropTypes from 'prop-types';
import variables from '../../../utils/variables';
import { networks } from '../../../networks';
import { chainConfig } from '../../../utils/chainConfig';
import { fetchRewards, initializeChain } from '../../../actions/account/wallet';
import { fetchPrice, setNetworkList } from '../../../actions/network';
import CopyButton from '../../../components/CopyButton';
import { fetchBalance } from '../../../actions/account/BCDetails';
import { commaSeparator, splitDecimals } from '../../../utils/numbers';
import { getProposals } from '../../../actions/proposals';
import { connect } from 'react-redux';

const Networks = (props) => {
    const initKeplr = (name) => {
        props.initializeChain(name, props.chainsList[name], chainConfig(props.chainsList[name]), (address) => {
            if (address.length && address[0] && address[0].address) {
                let obj = sessionStorage.getItem('of_insync_connections') || '{}';
                obj = obj && JSON.parse(obj);
                obj[name] = address[0].address;
                obj = obj && JSON.stringify(obj);
                sessionStorage.setItem('of_insync_connections', obj);
                const list = new Set(props.networkList);

                if (list.has(name)) {
                    list.delete(name);
                } else {
                    list.add(name);
                }

                const priceList = [];
                list.forEach((val) => {
                    if (networks[val].COINGECKO_ID !== '-') {
                        priceList.push(networks[val].COINGECKO_ID);
                    }

                    return null;
                });

                if (priceList.length) {
                    props.fetchPrice(priceList);
                }

                props.setNetworkList(list);
                props.fetchBalance(address[0].address, name, props.chainsList[name].REST_URL);
                props.getProposals(name, props.chainsList[name].REST_URL);
                props.fetchRewards(address[0].address, name, props.chainsList[name].REST_URL);
            }
        });
    };

    return (
        props.chainsList && Object.keys(props.chainsList).map((val) => {
            let balance = props.balance && props.balance[val];
            balance = balance && balance.length &&
                props.chainsList[val] && props.chainsList[val].COIN_MINIMAL_DENOM &&
                balance.find((value) => value.denom === props.chainsList[val].COIN_MINIMAL_DENOM);
            balance = balance && balance.amount && (balance.amount / (10 ** props.chainsList[val].COIN_DECIMALS));

            let price = props.chainsList[val] && props.chainsList[val].COINGECKO_ID && props.price[props.chainsList[val].COINGECKO_ID];
            price = price && price.usd;
            price = balance * price;
            price = price > 0 ? parseFloat(price).toFixed(4) : null;

            balance = balance > 0 ? parseFloat(balance).toFixed(4) : null;
            balance = balance && props.chainsList[val] && props.chainsList[val].COIN_DECIMALS &&
                splitDecimals(balance);

            const proposals = props.proposals && props.proposals[val] &&
                props.proposals[val].filter((proposal) => (proposal.status === 2 || proposal.status === 'PROPOSAL_STATUS_VOTING_PERIOD'));

            return (
                <div key={val} className="card">
                    <div className="network_info">
                        {props.chainsList[val].logo && <img alt={val} src={props.chainsList[val].logo}/>}
                        <div className="details">
                            <h2>{props.chainsList[val]['CHAIN_NAME']}</h2>
                            <div className="chain_id_parent">
                                <p className="chain_id">{props.chainsList[val]['CHAIN_ID']}</p>
                                <p className={props.chainsList[val]['NETWORK_TYPE']}>{props.chainsList[val]['NETWORK_TYPE']}</p>
                            </div>
                        </div>
                    </div>
                    {props.address && props.address[val]
                        ? <div className="account_info">
                            {balance && balance.length
                                ? <p className="balance">
                                    {balance.length && balance[0] && commaSeparator(balance[0])}
                                    {balance.length && balance[1] &&
                                        <span>.{balance.length && balance[1]}</span>}
                                    &nbsp;{props.chainsList[val].COIN_DENOM}
                                </p>
                                : <p className="balance">0 {props.chainsList[val].COIN_DENOM}</p>}
                            {price && <div className="dollar_value">~ ${price}</div>}
                            <div className="hash_text" title={props.address[val]}>
                                <p className="name">{props.address[val]}</p>
                                {props.address[val].slice(props.address[val].length - 6, props.address[val].length)}
                                <CopyButton data={props.address[val]}/>
                            </div>
                        </div> : null}
                    {props.address && props.address[val] && props.chainsList[val].APP_URL
                        ? <div className="button_div">
                            <Button
                                className="connect_button stake_button"
                                onClick={() => window.open(props.chainsList[val].OMNI_FLIX_LINK
                                    ? `${props.chainsList[val].APP_URL}/stake`
                                    : `${props.chainsList[val].APP_URL}`, '_self')}>
                                {variables[props.lang].stake}
                            </Button>
                            {proposals && proposals.length
                                ? <Button
                                    className="connect_button vote_button"
                                    onClick={() => window.open(props.chainsList[val].GOV_URL
                                        ? `${props.chainsList[val].GOV_URL}`
                                        : props.chainsList[val].OMNI_FLIX_LINK
                                            ? `${props.chainsList[val].APP_URL}/proposals`
                                            : `${props.chainsList[val].APP_URL}`, '_self')}>
                                    {variables[props.lang].vote}&nbsp;
                                    {`(${proposals.length})`}
                                </Button>
                                : null}
                        </div>
                        : props.address && props.address[val]
                            ? <Button className="connect_button" style={{ visibility: 'hidden' }}/>
                            : <Button className="connect_button" onClick={() => initKeplr(val)}>
                                {variables[props.lang].connect}
                            </Button>}
                </div>
            );
        })
    );
};

Networks.propTypes = {
    address: PropTypes.object.isRequired,
    balance: PropTypes.object.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchPrice: PropTypes.func.isRequired,
    fetchRewards: PropTypes.func.isRequired,
    getProposals: PropTypes.func.isRequired,
    initializeChain: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    networkList: PropTypes.object.isRequired,
    price: PropTypes.object.isRequired,
    proposals: PropTypes.object.isRequired,
    rewards: PropTypes.object.isRequired,
    setNetworkList: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
    chainsList: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        lang: state.language,
        networkList: state.network.networkList.value,
        price: state.network.price.value,
        proposals: state.proposals._.list,
        rewards: state.account.wallet.rewards.result,
        userName: state.account.wallet.userName,
    };
};

const actionToProps = {
    fetchBalance,
    fetchPrice,
    fetchRewards,
    getProposals,
    initializeChain,
    setNetworkList,
};

export default connect(stateToProps, actionToProps)(Networks);
