import { combineReducers } from 'redux';
import {
    CONNECT_KEPLR_ACCOUNT_ERROR,
    CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    CONNECT_KEPLR_ACCOUNT_SUCCESS,
    DISCONNECT_SET,
    KEPLR_ACCOUNT_KEYS_SET,
    REWARDS_FETCH_ERROR,
    REWARDS_FETCH_IN_PROGRESS,
    REWARDS_FETCH_SUCCESS,
    USER_NAME_SET,
} from '../../constants/wallet';

const connection = (state = {
    inProgress: false,
    address: {},
    keys: {},
}, action) => {
    switch (action.type) {
    case CONNECT_KEPLR_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CONNECT_KEPLR_ACCOUNT_SUCCESS: {
        if (action.value && action.value.length &&
            action.value[0] && action.value[0].address) {
            const obj = state.address;
            obj[action.name] = action.value[0].address;

            return {
                inProgress: false,
                address: obj,
            };
        } else {
            return {
                ...state,
                inProgress: false,
            };
        }
    }
    case CONNECT_KEPLR_ACCOUNT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case KEPLR_ACCOUNT_KEYS_SET:
        return {
            ...state,
            keys: action.value,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            address: {},
            keys: {},
        };
    default:
        return state;
    }
};

const userName = (state = '', action) => {
    switch (action.type) {
    case USER_NAME_SET:
        return action.value;
    case KEPLR_ACCOUNT_KEYS_SET:
        return action.value && action.value.name;
    case DISCONNECT_SET:
        return '';
    default:
        return state;
    }
};

const rewards = (state = {
    result: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case REWARDS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case REWARDS_FETCH_SUCCESS: {
        const obj = { ...state.result };
        obj[action.name] = action.value;
        return {
            ...state,
            inProgress: false,
            result: obj,
        };
    }
    case REWARDS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            result: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    connection,
    userName,
    rewards,
});
