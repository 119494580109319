import { combineReducers } from 'redux';
import { PROPOSALS_FETCH_ERROR, PROPOSALS_FETCH_IN_PROGRESS, PROPOSALS_FETCH_SUCCESS } from '../constants/proposals';

const _ = (state = {
    inProgress: false,
    list: {},
}, action) => {
    switch (action.type) {
    case PROPOSALS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROPOSALS_FETCH_SUCCESS: {
        const obj = state.list;
        obj[action.name] = action.list;

        return {
            list: obj,
            inProgress: false,
        };
    }
    case PROPOSALS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    _,
});
