import { SigningStargateClient } from '@cosmjs/stargate';

export const fetchKeplrAccountName = (name, config, chainConfig) => {
    window.keplr && window.keplr.getKey(config.CHAIN_ID)
        .then((res) => {
            console.log('111111', res);
            return res;
        }).catch(() => {

        });
};

export const signTxAndBroadcast = (tx, address, name, config, chainConfig, cb) => {
    (async () => {
        await window.keplr && window.keplr.enable(config.CHAIN_ID);
        const offlineSigner = window.getOfflineSignerOnlyAmino && window.getOfflineSignerOnlyAmino(config.CHAIN_ID);
        const client = await SigningStargateClient.connectWithSigner(
            config.RPC_URL,
            offlineSigner,
        );
        client.signAndBroadcast(
            address,
            tx.msgs ? tx.msgs : [tx.msg],
            tx.fee,
            tx.memo,
        ).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                cb(result.log || result.rawLog);
            } else {
                cb(null, result);
            }
        }).catch((error) => {
            cb(error && error.message);
        });
    })();
};
