import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import { fetchPrice, hideConnectDialog, setAllNetworkList, setNetworkList } from '../../../../actions/network';
import { Button, Checkbox, FormControlLabel, ListItem, RadioGroup } from '@mui/material';
import variables from '../../../../utils/variables';
import { networks } from '../../../../networks';
import './index.css';
import { chainConfig } from '../../../../utils/chainConfig';
import { fetchBalance } from '../../../../actions/account/BCDetails';
import { fetchRewards, initializeChain } from '../../../../actions/account/wallet';
import { getProposals } from '../../../../actions/proposals';

const ConnectDialog = (props) => {
    const {
        onClose,
        open,
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        const list = new Set(props.value);

        if (list.has(value)) {
            list.delete(value);
        } else {
            list.add(value);
        }

        if ((list.size === 0) && props.selectAll) {
            props.setAllNetworkList(false);
        } else if (networks && (Object.keys(networks).length === list.size)) {
            props.setAllNetworkList(true);
        }

        props.setNetworkList(list);
    };

    const handleConnect = () => {
        let obj = sessionStorage.getItem('of_insync_connections') || '{}';
        obj = obj && JSON.parse(obj);
        const array = [];
        const priceList = [];
        props.value.forEach((name) => {
            if (!obj[name]) {
                array.push(name);
            }

            if (networks[name].COINGECKO_ID !== '-') {
                priceList.push(networks[name].COINGECKO_ID);
            }
        });

        if (priceList.length) {
            props.fetchPrice(priceList);
        }
        handleFetch(array, obj);
    };

    const handleFetch = (data, object) => {
        if (data && data.length && data[0]) {
            const name = data[0];
            props.initializeChain(name, networks[name], chainConfig(networks[name]), (address) => {
                if (address.length && address[0] && address[0].address) {
                    object[name] = address[0].address;

                    props.fetchBalance(address[0].address, name, networks[name].REST_URL);
                    props.getProposals(name, networks[name].REST_URL);
                    props.fetchRewards(address[0].address, name, networks[name].REST_URL);

                    data.splice(0, 1);
                    handleFetch(data, object);
                }
            });
        } else {
            object = object && JSON.stringify(object);
            sessionStorage.setItem('of_insync_connections', object);
            onClose();
        }
    };

    const handleSelectAll = () => {
        const list = new Set(props.value);
        if (props.selectAll) {
            list.clear();
        } else {
            networks && Object.keys(networks).map((value) => {
                if (!list.has(value)) {
                    list.add(value);
                }

                return null;
            });
        }

        props.setAllNetworkList(!props.selectAll);
        props.setNetworkList(list);
    };

    let chainsList = networks;
    if (props.network === 'main') {
        chainsList = {};
        networks && Object.keys(networks).filter((val) => {
            if (networks[val]['NETWORK_TYPE'] === 'mainnet') {
                chainsList[val] = networks[val];
            }

            return null;
        });
    } else if (props.network === 'test') {
        chainsList = {};
        networks && Object.keys(networks).filter((val) => {
            if (networks[val]['NETWORK_TYPE'] === 'testnet') {
                chainsList[val] = networks[val];
            }

            return null;
        });
    }

    return (
        <Dialog className="network_dialog" open={open} onClose={handleClose}>
            <div className="section_header">
                <RadioGroup
                    row
                    aria-label="position"
                    name="position">
                    <FormControlLabel
                        control={<Checkbox checked={props.selectAll} color="primary" onChange={handleSelectAll}/>}
                        label={variables[props.lang]['select_all']}
                        labelPlacement="end"
                        value="end"/>
                </RadioGroup>
                <Button onClick={handleConnect}>
                    {variables[props.lang].connect}
                    <p>({props.value.size})</p>
                </Button>
            </div>
            {/* <DialogTitle>{variables[props.lang]['select_networks']}</DialogTitle> */}
            <List className="scroll_bar" sx={{ pt: 0 }}>
                {chainsList && Object.keys(chainsList).map((val) => (
                    <ListItem
                        key={val}
                        className={props.value.has(val) ? 'checked_network' : ''}
                        onClick={() => handleListItemClick(val)}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={props.value.has(val)}
                                sx={{
                                    color: '#ffffff',
                                    '&.Mui-checked': {
                                        color: '#6228E6',
                                    },
                                }}
                            />}
                            label={<div className="chain_details" onClick={(event) => event.stopPropagation()}>
                                <span className="chain_logo">{networks[val].logo &&
                                    <img alt={val} src={networks[val].logo}/>}</span>
                                <span className="chain_name">{networks[val]['CHAIN_NAME']}</span>
                            </div>}/>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

ConnectDialog.propTypes = {
    fetchBalance: PropTypes.func.isRequired,
    fetchPrice: PropTypes.func.isRequired,
    fetchRewards: PropTypes.func.isRequired,
    getProposals: PropTypes.func.isRequired,
    initializeChain: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    network: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    selectAll: PropTypes.bool.isRequired,
    setAllNetworkList: PropTypes.func.isRequired,
    setNetworkList: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.network.connectDialog,
        value: state.network.networkList.value,
        selectAll: state.network.networkList.selectAll,
        network: state.network.networkValue.value,
    };
};

const actionToProps = {
    fetchBalance,
    fetchPrice,
    fetchRewards,
    getProposals,
    initializeChain,
    onClose: hideConnectDialog,
    setAllNetworkList,
    setNetworkList,
};

export default connect(stateToProps, actionToProps)(ConnectDialog);
