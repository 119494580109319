import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import './index.css';
import { showClaimDialog, showConnectDialog } from '../../../../actions/network';
import variables from '../../../../utils/variables';
import { setDisconnect } from '../../../../actions/account/wallet';

const Profile = (props) => {
    const handleClick = () => {
        props.setDisconnect();
        sessionStorage.clear();
    };

    let totalPrice = 0;
    if (props.chainsList && Object.keys(props.chainsList).length) {
        Object.keys(props.chainsList).map((val) => {
            let balance = props.balance && props.balance[val];
            balance = balance && balance.length &&
                props.chainsList[val] && props.chainsList[val].COIN_MINIMAL_DENOM &&
                balance.find((value) => value.denom === props.chainsList[val].COIN_MINIMAL_DENOM);
            balance = balance && balance.amount && (balance.amount / (10 ** props.chainsList[val].COIN_DECIMALS));

            let price = props.chainsList[val] && props.chainsList[val].COINGECKO_ID && props.price[props.chainsList[val].COINGECKO_ID];
            price = price && price.usd;
            price = balance * price;
            price = price > 0 ? parseFloat(price).toFixed(4) : 0;
            totalPrice = totalPrice + Number(price);

            return null;
        });
    }

    const amount = new Intl.NumberFormat('en-US').format(totalPrice);
    return (
        Object.keys(props.address).length === 0
            ? null
            : <div className="section2">
                <div className="left">
                    <span>{props.userName}</span>
                    {totalPrice > 0 && <p>${amount}</p>}
                </div>
                <div className="right">
                    <Button className="disconnect" onClick={() => handleClick()}>
                        {variables[props.lang].disconnect}
                    </Button>
                    <Button className="claim_reward" onClick={props.showClaimDialog}>
                        {variables[props.lang]['claim_rewards']}
                    </Button>
                </div>
            </div>
    );
};

Profile.propTypes = {
    address: PropTypes.object.isRequired,
    balance: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    price: PropTypes.object.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    showClaimDialog: PropTypes.func.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
    chainsList: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        balance: state.account.bc.balance.value,
        lang: state.language,
        price: state.network.price.value,
        userName: state.account.wallet.userName,
    };
};

const actionToProps = {
    setDisconnect,
    showConnectDialog,
    showClaimDialog,
};

export default connect(stateToProps, actionToProps)(Profile);
