export const chainConfig = (value) => {
    const prefix = value.PREFIX;
    const chain = {
        chainId: value.CHAIN_ID,
        chainName: value.CHAIN_NAME,
        rpc: value.RPC_URL,
        rest: value.REST_URL,
        stakeCurrency: {
            coinDenom: value.COIN_DENOM,
            coinMinimalDenom: value.COIN_MINIMAL_DENOM,
            coinDecimals: value.COIN_DECIMALS,
            coinGeckoId: value.COINGECKO_ID,
        },
        bip44: {
            coinType: 118,
        },
        bech32Config: {
            bech32PrefixAccAddr: `${prefix}`,
            bech32PrefixAccPub: `${prefix}pub`,
            bech32PrefixValAddr: `${prefix}valoper`,
            bech32PrefixValPub: `${prefix}valoperpub`,
            bech32PrefixConsAddr: `${prefix}valcons`,
            bech32PrefixConsPub: `${prefix}valconspub`,
        },
        currencies: [{
            coinDenom: value.COIN_DENOM,
            coinMinimalDenom: value.COIN_MINIMAL_DENOM,
            coinDecimals: value.COIN_DECIMALS,
            coinGeckoId: value.COINGECKO_ID,
        }],
        feeCurrencies: [{
            coinDenom: value.COIN_DENOM,
            coinMinimalDenom: value.COIN_MINIMAL_DENOM,
            coinDecimals: value.COIN_DECIMALS,
            coinGeckoId: value.COINGECKO_ID,
        }],
        coinType: value.COIN_TYPE,
        gasPriceStep: {
            low: value.GAS_PRICE_STEP_LOW,
            average: value.GAS_PRICE_STEP_AVERAGE,
            high: value.GAS_PRICE_STEP_HIGH,
        },
        features: value.FEATURES,
    };

    if (!value.OMNI_FLIX_LINK && value.APP_URL) {
        chain.walletUrlForStaking = `${value.APP_URL}`;
    } else if (value.APP_URL) {
        chain.walletUrlForStaking = `${value.APP_URL}/stake`;
    }

    return chain;
};
