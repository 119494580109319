import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import './index.css';
import CircularProgress from '../../components/CircularProgress';

class LoadingPage extends Component {
    componentDidMount () {
        this.props.history.push({
            pathname: '/home',
        });
    }

    componentDidUpdate (pp, ps, ss) {
        this.props.history.push({
            pathname: '/home',
        });
    }

    render () {
        return (
            <div className="loader_page">
                <CircularProgress/>
            </div>
        );
    }
}

LoadingPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default LoadingPage;
