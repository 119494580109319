import {
    ALL_CLAIM_LIST_SET,
    ALL_NETWORK_LIST_SET,
    CLAIM_DIALOG_HIDE,
    CLAIM_DIALOG_SHOW,
    CLAIM_LIST_SET,
    CONNECT_DIALOG_HIDE,
    CONNECT_DIALOG_SHOW,
    NETWORK_LIST_SET,
    NETWORK_SET,
    PRICE_FETCH_ERROR,
    PRICE_FETCH_IN_PROGRESS,
    PRICE_FETCH_SUCCESS,
    SEARCH_VALUE_SET,
} from '../constants/network';
import { urlFetchPrice } from '../constants/restURL';
import Axios from 'axios';

export const setNetwork = (value) => {
    return {
        type: NETWORK_SET,
        value,
    };
};

export const setSearchValue = (value) => {
    return {
        type: SEARCH_VALUE_SET,
        value,
    };
};

export const showConnectDialog = () => {
    return {
        type: CONNECT_DIALOG_SHOW,
    };
};

export const hideConnectDialog = () => {
    return {
        type: CONNECT_DIALOG_HIDE,
    };
};

export const setNetworkList = (value) => {
    return {
        type: NETWORK_LIST_SET,
        value,
    };
};

export const setAllNetworkList = (value) => {
    return {
        type: ALL_NETWORK_LIST_SET,
        value,
    };
};

export const showClaimDialog = () => {
    return {
        type: CLAIM_DIALOG_SHOW,
    };
};

export const hideClaimDialog = () => {
    return {
        type: CLAIM_DIALOG_HIDE,
    };
};

export const setClaimList = (value) => {
    return {
        type: CLAIM_LIST_SET,
        value,
    };
};

export const setAllClaimList = (value) => {
    return {
        type: ALL_CLAIM_LIST_SET,
        value,
    };
};

const fetchPriceInProgress = () => {
    return {
        type: PRICE_FETCH_IN_PROGRESS,
    };
};

const fetchPriceSuccess = (value) => {
    return {
        type: PRICE_FETCH_SUCCESS,
        value,
    };
};

const fetchPriceError = (message) => {
    return {
        type: PRICE_FETCH_ERROR,
        message,
    };
};

export const fetchPrice = (coins) => (dispatch) => {
    dispatch(fetchPriceInProgress());
    const url = urlFetchPrice(coins);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchPriceSuccess(res.data));
        })
        .catch((error) => {
            dispatch(fetchPriceError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
