import { combineReducers } from 'redux';
import { SEARCH_VALIDATORS_SET } from '../constants/validators';

const searchValidators = (state = {
    value: '',
}, action) => {
    if (action.type === SEARCH_VALIDATORS_SET) {
        return {
            value: action.value,
        };
    }

    return state;
};

export default combineReducers({
    searchValidators,
});
