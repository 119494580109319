import { combineReducers } from 'redux';
import {
    BALANCE_FETCH_ERROR,
    BALANCE_FETCH_IN_PROGRESS,
    BALANCE_FETCH_SUCCESS,
    DISCONNECT_SET,
} from '../../constants/wallet';

const balance = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case BALANCE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case BALANCE_FETCH_SUCCESS: {
        const object = { ...state.value };
        object[action.name] = action.value;

        return {
            inProgress: false,
            value: object,
        };
    }
    case BALANCE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    balance,
});
